import React, { useState } from "react";
import { StyledContactForm, StyledContactWrapper } from "./ContactForm.styled";
import { Button } from "../../styles/ButtonStyles";
import useForm from "../../utils/useForm";

export default function ContactForm() {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { values, updateValues } = useForm({
    name: "",
    email: "",
    message: "",
    liquidSugar: "",
  });

  const date = new Date();
  const fullDate =
    date.getFullYear() +
    "-" +
    parseInt(date.getMonth() + 1) +
    "-" +
    date.getDate();

  async function handleSubmit(e) {
    e.preventDefault();
    setError(false);
    setIsFetching(true);

    const data = {
      name: values.name,
      email: values.email,
      message: values.message,
      date: fullDate,
    };

    function submitStatus(formSuccess) {
      setIsFetching(false);
      if (formSuccess) {
        setSuccess(true);
        updateValues();
      } else {
        setError(true);
        setSuccess(false);
      }
    }

    try {
      // auto response email
      const sgResponse = await fetch(
        `${process.env.GATSBY_SERVERLESS_BASE}/sendgrid`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      // const sgText = JSON.parse(await sgResponse.text());

      // encode data for netlify
      function encode(formData) {
        return Object.keys(formData)
          .map(
            key =>
              encodeURIComponent(key) + "=" + encodeURIComponent(formData[key])
          )
          .join("&");
      }

      // netlify collect submission
      const netlifyResponse = await fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: encode({
          "form-name": "contact",
          subject: `New message from ${values.name}`,
          ...data,
        }),
      });

      if (!sgResponse.ok || !netlifyResponse.ok) {
        submitStatus(false);
        return;
      } else {
        submitStatus(true);
      }
    } catch (e) {
      submitStatus(false);
      console.error(e);
    }
  }

  return (
    <StyledContactWrapper>
      {success && (
        <p className="submit-message success">Thanks for reaching out!</p>
      )}
      {error && (
        <p className="submit-message error">
          There was an error submitting your message. If the problem persists,
          please email us directly.
        </p>
      )}
      {isFetching && (
        <div className="loading-div-group">
          <div className="loading-div"></div>
          <div className="loading-div"></div>
          <div className="loading-div"></div>
        </div>
      )}
      <StyledContactForm
        method="post"
        data-netlify="true"
        netlify-honeypot="liquidSugar"
        id="contactFormSection"
        name="contact"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="inputGroup">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={values.name}
            onChange={updateValues}
            required
          />
        </div>
        <div className="inputGroup">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={values.email}
            onChange={updateValues}
            required
          />
        </div>
        <div className="inputGroup">
          <label htmlFor="message">Message</label>
          <textarea
            type="text"
            name="message"
            id="message"
            value={values.message}
            onChange={updateValues}
            required
          />
        </div>
        <input hidden aria-hidden="true" type="text" value={fullDate} name="date" readOnly />
        <div hidden aria-hidden="true">
          <label>
            How old are you?
            <input
              name="liquidSugar"
              type="text"
              value={values.liquidSugar}
              onChange={updateValues}
            />
          </label>
        </div>
        <Button
          primary
          type="submit"
          aria-label="submit"
          name="submit"
          disabled={isFetching}
        >
          Submit
        </Button>
      </StyledContactForm>
    </StyledContactWrapper>
  );
}
