import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { StyledSocialContainer } from "./SocialContainer.styled";

export default function SocialContainer() {
  return (
    <StyledSocialContainer>
      <a
        href="https://www.facebook.com/Reclaim-Justice-Movement-105200225025436"
        target="_blank"
      >
        <FaFacebook alt="facebook" title="facebook" className="fb" />
      </a>
      <FiMail alt="email" title="email" />
    </StyledSocialContainer>
  );
}
