import styled, { css } from "styled-components";
import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const ButtonStyles = css`
  padding: 0.75rem 1.5em;
  border-radius: 5px;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: ${({ theme, primary }) =>
    primary ? theme.primaryPop : theme.primaryDark};
  color: ${({ theme, primary }) =>
    primary ? theme.primaryLight : theme.primaryLight};
  font-size: 1.1rem;
  ${"" /* transition: .1s ease-in-out box-shadow; */}
  &:hover {
    background: ${({ theme, primary }) =>
      primary ? theme.primaryHover : theme.primaryHover};
    color: ${({ theme, primary }) =>
      primary ? theme.primaryLight : theme.primaryLight};
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 13%);
  }
`;

export const Button = styled.button`
  ${ButtonStyles}
`;

export const StyledAnchorLink = styled(props => <AnchorLink {...props} />)`
  ${ButtonStyles}
`;
