import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import ContactForm from "../components/ContactForm";
import Section from "../components/Section";
import SocialContainer from "../components/SocialContainer";
import { StyledAnchorLink } from "../styles/ButtonStyles";
import { FaChevronDown } from "react-icons/fa";
import { BiHealth } from "react-icons/bi";
import { above, below } from "../styles/Breakpoints";
import SEO from "../components/SEO";

const HomeStyles = styled.div`
  padding-bottom: 2.5rem;
  & section:first-of-type p {
    font-size: clamp(0.8rem, (0.5rem + 2vw), 1.05rem);
    position: relative;
    right: -0.5rem;
    ${above.laptop`
      line-height: 1.65rem;
    `}
  }

  .chevDown {
    ${below.mobileL`
      display: none;
      visibility: hidden;
    `}
    @media (max-height: 850px) {
      display: none;
    }
  }

  .homepage-list {
    margin-inline-start: -2rem;
    & li {
      /* display: inline-flex; */
      display: flex;
      & svg {
        max-width: 14px;
        width: 100%;
        height: 100%;
        margin-top: 7px;
        margin-right: 0.25rem;
        color: var(--primaryPop);
      }

      & strong {
        display: inline;
      }

      & p {
        margin-bottom: 0;
      }
    }
  }

  .contact-anchor {
    top: 9rem;
    position: absolute;
    visibility: hidden;
  }

  .contact-section {
    position: relative;
    ${below.mobileL`
      padding-top: 0;
    `}
  }

  .contact-header {
    margin-bottom: 3rem;
    text-transform: initial;
  }

  h2 {
    margin-bottom: 1.5rem;
  }

  @keyframes growLine {
    0% {
      width: 0;
      opacity: 0;
    }
    100% {
      width: 100%;
      opacity: 1;
    }
  }

  .header-underline {
    margin: 0 auto 0.5rem;
    animation: 1.5s ease-out 0.5s growLine 1 forwards;
    width: 0;
    &.loaded {
      animation: none;
      width: 600px;
    }
  }
`;

function Home({ ...props }) {
  let { loaded } = props;
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    setInitialLoad(loaded);
  }, []);

  const divRef = useRef();

  const { location } = props;

  function handleChevDownClick() {
    divRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <SEO location={location} />
      <HomeStyles>
        <Section flow fullScreen className="intro-section" width="650px">
          <h1>Reclaim Justice</h1>
          <div
            className={
              initialLoad ? "header-underline loaded" : "header-underline"
            }
          ></div>
          <p>
            The Reclaim Justice Movement is a 501(c)(3) non-profit organization
            that:
          </p>
          <ul className="homepage-list">
            <li>
              <BiHealth />
              <p>
                redefines justice as healing and connection rather than
                punishment.
              </p>
            </li>
            <li>
              <BiHealth />
              <p>
                promotes effective <strong>ways to respond to</strong> violence
                that help people and communities heal.
              </p>
            </li>
            <li>
              <BiHealth />
              <p>
                challenges common beliefs about justice through education,
                workshops, direct services, independent research projects, and
                working with other organizations.
              </p>
            </li>
          </ul>
          <p style={{ marginBottom: 0, fontWeight: "600" }}>
            Specifically, we aim to:
          </p>
          <ul className="homepage-list">
            <li>
              <BiHealth />
              <p>
                talk about <strong>transformative</strong> and{" "}
                <strong>restorative justice</strong> ideas using a mix of
                educational and public media tools.
              </p>
            </li>
            <li>
              <BiHealth />
              <p>
                build upon{" "}
                <strong>transformative and restorative justice concepts</strong>{" "}
                through independent research.
              </p>
            </li>
            <li>
              <BiHealth />
              <p>
                provide services directly to individuals and groups that support{" "}
                <strong>transformative</strong> and{" "}
                <strong>restorative justice practices</strong>, for example,
                consultation, coaching and therapy.
              </p>
            </li>
          </ul>

          <StyledAnchorLink
            primary
            to="/#contactFormSection"
            className="buttonStyles"
            title="Join The Movement"
          ></StyledAnchorLink>
          <FaChevronDown
            className="chevDown"
            onClick={handleChevDownClick}
            style={{ marginBottom: "-1rem" }}
          />
        </Section>
        {/* <Section flow>
        <span
          ref={divRef}
          style={{ top: "0", position: "absolute", visibility: "hidden" }}
        ></span>
        <h2>Who We Are</h2>
        <img src="https://source.unsplash.com/wJK9eTiEZHY/600x400" alt="" />
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam, harum
          pariatur at doloremque nihil eaque voluptates non corporis eligendi
          quibusdam officiis beatae accusantium consequuntur suscipit sequi
          facere libero? Cum, debitis? Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Laborum commodi error voluptas sed nobis nam odit
          corporis minima ipsa? Labore ipsam maiores explicabo numquam
          consectetur hic dignissimos voluptates necessitatibus iste.
        </p>
        <Link className="headerLink" to="/about">
          Learn More
        </Link>
      </Section>
      <Section flow id="whatSection">
        <h2>What We Do</h2>
        <CardContainer>
          <div>
            <img src="https://source.unsplash.com/random" alt="" />
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facilis,
              laboriosam debitis, voluptate et reprehenderit veritatis cumque
              atque cupiditate ipsa expedita ullam vitae? Architecto cupiditate
              maiores eum minus quod et in.
            </p>
          </div>
          <div>
            {" "}
            <img src="https://source.unsplash.com/random" alt="" />
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facilis,
              laboriosam debitis, voluptate et reprehenderit veritatis cumque
              atque cupiditate ipsa expedita ullam vitae? Architecto cupiditate
              maiores eum minus quod et in.
            </p>
          </div>
          <div>
            {" "}
            <img src="https://source.unsplash.com/random" alt="" />
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facilis,
              laboriosam debitis, voluptate et reprehenderit veritatis cumque
              atque cupiditate ipsa expedita ullam vitae? Architecto cupiditate
              maiores eum minus quod et in.
            </p>
          </div>
        </CardContainer>
        <Link className="headerLink" to="/about">
          Learn More
        </Link>
      </Section> */}
        <Section center fullScreen className="contact-section">
          <h2 className="contact-header">Connect with us.</h2>
          <span ref={divRef} id="getInTouch" className="contact-anchor"></span>
          <ContactForm />
          <SocialContainer />
        </Section>
      </HomeStyles>
    </>
  );
}

export default Home;
