import styled from "styled-components";

export const StyledSocialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 350px;
  width: 100%;

  @media (max-width: 400px) {
    width: 90%;
    margin-top: -1rem;
  }

  & svg {
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: 0.2s ease color;
    &:hover {
      color: var(--primaryPop);
    }
    &.fb {
      width: 38px;
      height: 38px;
    }
    @media (max-width: 400px) {
      width: 34px;
      height: 34px;
    }
  }
`;
