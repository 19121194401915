import styled from "styled-components";
import { above, below } from "../../styles/Breakpoints";

export const StyledContactForm = styled.form`
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      display: none !important;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 350px;
  border: 1px solid var(--softAltBg);
  border-radius: 5px;
  padding: 1.5rem 2rem;
  background: var(--white);
  box-shadow: 2px 2px #0000001a;
  margin-bottom: 4rem;
  transition: 0.5s ease all;
  ${below.mobileL`
    padding: 1.5rem;
  `}

  & .inputGroup {
    display: flex;
    margin-bottom: 1rem;
    align-items: flex-start;
    flex-wrap: wrap;
    flex: 1;
    width: 100%;

    & input,
    textarea {
      width: 100%;
      border-radius: 2px;
      outline: none;
      border: 1px solid var(--softAltBg);
      ${below.mobileL`
        font-size: 16px;
      `}
    }

    & input {
      min-height: 2rem;
    }

    & label {
      margin-right: 1rem;
      margin-bottom: 0.25rem;
      font-weight: bold;
      color: var(--black);
    }
  }

  & textarea {
    height: 225px;
  }

  & input[type="text"],
  input[type="email"],
  textarea {
    background: var(--formgray);
    padding: 0.5rem;
    letter-spacing: 0.6px;
    color: var(--primaryPop);
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-display: swap;
    transition: 0.2s ease background;
    &:focus {
      background: var(--formBg);
    }
  }

  & button {
    margin-top: 0.5rem;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--primaryPop);
    box-shadow: 0 0 0px 1000px var(--altSoftBg) inset;
    -webkit-box-shadow: 0 0 0px 1000px var(--altSoftBg) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const StyledContactWrapper = styled.div`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  position: relative;

  & .submit-message {
    max-width: 330px;
    animation: 1.5s fadeIn ease-in forwards;
    font-size: 0.85rem;
    position: absolute;
    top: -3rem;
    &.success {
      text-align: center;
      top: -2.5rem;
      font-size: 1rem;
      font-style: italic;
    }
  }

  @keyframes bounceUp {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .loading-div {
    width: 0.5rem;
    height: 0.5rem;
    background: var(--primaryPop);
    border-radius: 50%;
    position: relative;
    &:first-child {
      animation: 0.5s bounceUp 0s ease alternate infinite;
    }
    &:nth-child(2) {
      animation: 0.5s bounceUp 0.1s ease alternate infinite;
    }
    &:last-child {
      animation: 0.5s bounceUp 0.2s ease alternate infinite;
    }

    &-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 40px;
      margin: 0 auto;
      height: 30px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -2.75rem;
    }
  }
`;
